import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';


class AccreditedCourse extends Component {
    getRow = (course, key) => <Grid.Row>
        <Grid.Column width={2}>{key} {course.award}</Grid.Column>
        <Grid.Column width={4}>{course.startDate} - {course.endDate}</Grid.Column>
        <Grid.Column width={10}>
            <p className="course-name">{course.courseTitle}</p>
            {course.modesOfStudy.map((x, i) => <Expander key={i} type={x.type} conditions={x.conditions} accreditations={x.accreditations} />)}
        </Grid.Column>
    </Grid.Row>

    render() {
        return this.getRow(this.props.course)
    }
}

class Expander extends Component {
    constructor(props) {
        super(props);
        this.state = { collapsed: true };
    }

    handleKeyPress = (event) => {
        if (event.charCode === 32 || event.charCode === 13) {
            this.setState({ collapsed: !this.state.collapsed });
            event.stopPropagation();
            event.preventDefault();
        }
    }

    render() {
        return (
            <div >
                <Grid className={`study-mode ${this.state.collapsed ? 'collapsed' : ''}`}>
                    <Grid.Row>
                        <Grid.Column tabIndex={0} onKeyPress={this.handleKeyPress} onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }} className="mode-title">{this.props.type} {this.state.collapsed ?
                            <Icon name='plus' /> : <Icon name='minus' />}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} className="sub-section">
                        <Grid.Column>
                            <span>Accreditation:</span>
                        </Grid.Column>
                        <Grid.Column>
                            {this.props.accreditations.map(y => <span key={y} className="block">{y}</span>)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} className="sub-section">
                        <Grid.Column>
                            <span>Conditions:</span>
                        </Grid.Column>
                        <Grid.Column>
                            {this.props.conditions}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>)
    }
}

export default AccreditedCourse;