import React, { Component } from 'react';
import { ValidatableDropdown } from './validatable-input';
import { Grid, Form, Input, Label, Button, Loader } from 'semantic-ui-react';
import Results from './results.jsx';
import update from 'immutability-helper';
import { FormFeedback } from 'reactstrap';

import settings from './appSettings.js';

const buildQuery = (data) => {
    var query = '';
    if (data.institution) {
        query = `${query}inst=${data.institution.value}`;
    }

    if (data.course !== '') {
        query = `${query}&course=${data.course}`;
    }

    if (data.startYear) {
        query = `${query}&syear=${data.startYear.value}`;
    }

    if (data.endYear) {
        query = `${query}&eyear=${data.endYear.value}`;
    }

    if (data.award) {
        query = `${query}&award=${data.award.value}`;
    }

    if (data.studyMode) {
        query = `${query}&mode=${data.studyMode.value}`;
    }

    if (data.accreditation) {
        query = `${query}&accred=${data.accreditation.value}`;
    }

    return query;
}

const getYearOptions = (yearType) => {
    var currentYear = new Date().getFullYear();

    if(yearType === 'end')
        currentYear = currentYear + 10;

    if(yearType === 'start')
        currentYear = currentYear + 5;

    var years = [];
    years.push({ key: -1, label: 'Select...', value: null });
    for (var i = currentYear; i >= 1966; i--) {
        years.push({ key: i, label: i, value: i });
    }

    return years;
}

export default class AccreditationSearch extends Component {
    constructor() {
        super();

        this.state = {
            values: { course: '' },
            results: [],
            errors: {},
            showResults: false,
            isDirty: false,
            hasError: false,
            isSearching: false,
            errorMessage: ''
        }

        this.baseUrl = settings.arcUrl;

        this.initSearchParams();
    }

    static defaultProps = {
        courseName: ''
    }

    async initSearchParams() {
        try {
            var result = await fetch(`${this.baseUrl}/searchParams`);
            var jsonResult = await result.json();

            var initialModes = [{ value: null, label: 'Please select', key: -1 }]
            var initialAwards = [{ value: null, label: 'Please select', key: -1 }]
            var initialAccreditations = [{ value: null, label: 'Please select', key: -1 }]

            jsonResult.modes.forEach((val) => { initialModes.push({ value: val, label: val, key: val }) });
            jsonResult.awards.forEach((val) => { initialAwards.push({ value: val, label: val, key: val }) });
            jsonResult.accreditations.forEach((val) => { initialAccreditations.push({ value: val, label: val, key: val })})
            
            this.setState({
                modes: initialModes,
                awards: initialAwards,
                accreditations: initialAccreditations
            });
        } catch (exception) {
            console.log(`Error initialising search parameters: ${exception}`);
        }
    }

    updateDropdownHandler(index, e) {
        // if we have cleared the value clear the object
        if (e === null || e.value === null) {
            e = null;
        }

        this.setState({ isDirty: true, values: update(this.state.values, { [index]: { $set: e } }) });
    }

    updateHandler(index, e) {
        this.setState({ values: update(this.state.values, { [index]: { $set: e.target.value } }) });
    }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    async searchInstitution(input, callback) {
        if (input && input.length >= 3) {
            try {
                var result = await fetch(`${this.baseUrl}/institution?q=${input}`);
                var jsonResult = await result.json();

                var list = jsonResult.map(val => { return { key: val.key, value: val.key, label: val.value } });
                callback(list)
            } catch (exception) {
                console.log(`Error fetching list of institutions: ${exception}`);
            }
        }

        return null;
    }

    checkFields() {
        let requiredFields = ['institution', 'course'];

        let fields = ['institution', 'startYear', 'endYear', 'award', 'studyMode', 'course', 'accreditation'];
        var returnVal = true;
        var tempErrors = this.state.errors;

        for (let field of fields) {
            var currValue = this.state.values[field];
            tempErrors[field] = undefined;

            if (requiredFields.includes(field) && !currValue) {
                tempErrors[field] = 'Required field';
                returnVal = false;
            }
        }

        // if start or end date is present then both must be
        if ((this.state.values['startYear'] && !this.state.values['endYear'])
            || (!this.state.values['startYear'] && this.state.values['endYear'])) {
            tempErrors['startYear'] = 'Both a start and end year must be provided';
            returnVal = false;
        }

        this.setState({ errors: tempErrors });

        return returnVal;
    }

    async findQualifications(e) {
        e.preventDefault();
        
        if (!this.checkFields()) {
            return;
        }

        this.setState({ isSearching: true})

        try {
            var query = buildQuery(this.state.values);
            var result = await fetch(`${this.baseUrl}/publicsearch?${query}`);
            var jsonResult = await result.json();
            console.log(jsonResult);
            if (jsonResult.statusCode.toLowerCase() === 'success') {
                var res = jsonResult.results;
                this.setState({ results: res, showResults: true, isDirty: false, isSearching: false, hasError: false });
            }else{
                this.setState({ errorMessage: jsonResult.errorReference, isSearching: false, hasError: true });
            }

        } catch (exception) {
            console.log(`Error finding qualifications: ${exception}`);
            this.setState({ errorMessage: 'An error occurred fetching results', hasError: true, isSearching: false });
        }
    }

    render() {
        return (
            <div className="feature-box">
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Form.Group className="form-group">
                                <Label>Place of study</Label>
                                <ValidatableDropdown noOptionsMessage={() => 'No institutes found'} async cacheOptions loadOptions={this.searchInstitution.bind(this)} error={this.state.errors['institution']} onChange={this.updateDropdownHandler.bind(this, 'institution')} placeholder="Type to start searching" />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form.Group>
                                <Label>Course name</Label>
                                <Input onChange={this.updateHandler.bind(this, 'course')} fluid placeholder='Course name' />
                                <FormFeedback className="text-danger">{this.state.errors['course']}</FormFeedback>
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Group className="form-group">
                                <span className="optional-label">Optional</span>
                                <Label>Award</Label>
                                <ValidatableDropdown options={this.state.awards} error={this.state.errors['award']} onChange={this.updateDropdownHandler.bind(this, 'award')} placeholder="Please select" />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Group className="form-group">
                                <span className="optional-label">Optional</span>
                                <Label>Type of study</Label>
                                <ValidatableDropdown options={this.state.modes} error={this.state.errors['studyMode']} onChange={this.updateDropdownHandler.bind(this, 'studyMode')} placeholder="Please select" />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}>
                            <Form.Group className="form-group">
                                <Label>Start year</Label>
                                <ValidatableDropdown options={getYearOptions('start')} error={this.state.errors['startYear']} onChange={this.updateDropdownHandler.bind(this, 'startYear')} placeholder="Year" />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Group className="form-group">
                                <span className="optional-label">Optional</span>
                                <Label>End year</Label>
                                <ValidatableDropdown options={getYearOptions('end')} error={this.state.errors['endYear']} onChange={this.updateDropdownHandler.bind(this, 'endYear')} placeholder="Year" />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form.Group className="form-group">
                                <span className="optional-label">Optional</span>
                                <Label>Accredited for</Label>
                                <ValidatableDropdown options={this.state.accreditations} error={this.state.errors['accreditation']} onChange={this.updateDropdownHandler.bind(this, 'accreditation')} placeholder="Please select" />
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8} className="search-bottom-spacer">
                            <Button className="primary" disabled={this.state.isSearching} onClick={this.findQualifications.bind(this)}>Find accredited courses <Loader active={this.state.isSearching}  /></Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.state.hasError ? <div className="error-box text-danger">{this.state.errorMessage}</div> : null}
                {this.state.showResults ?
                    <Results results={this.state.results} onChange={this.onChange.bind(this)} isDirty={this.state.isDirty} />
                    : <div></div>}
            </div>);
    }
}