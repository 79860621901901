import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';

import  AccreditationSearch from './search';
import './styles.css';
import settings from './appSettings';

class App extends Component {
  render() {
    return (
      <div className="app">
        <header className="app-header">
          <img src="https://cdn.bcs.org/resources/images/logo.jpg" alt="" className="logo" />
          <a href={settings.bcsUrl}>Return to bcs.org</a> 
          <h1 className="page-title">Accredited course search</h1>
          <p>All undergraduate degrees accredited by BCS for full CITP, are recognised under the Seoul Accord which aims to establish and promote mutual recognition of academic programmes among member countries.</p>
          <p>This recognition enhances the credibility and quality assurance of academic programmes, allowing students to have their qualifications recognised globally. It helps students and graduates in their pursuit of international education, employment, and professional mobility.</p>
        </header>
        <main className="app-body">
          <AccreditationSearch />
        </main>
      </div>
    );
  }
}

export default App;
