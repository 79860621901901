import React from 'react';
import { Input, FormFeedback } from 'reactstrap';

export default class ValidatableInput extends React.Component {
	render() {
		var opts = {};
		if (this.props.error !== undefined) {
			opts["state"] = "danger";
		}

		const inputProps = Object.assign({}, this.props);
		delete inputProps.error;
		delete inputProps.value;

		let feedback;

		if (this.props.error !== undefined) {
			feedback = <FormFeedback className="text-danger">{this.props.error}</FormFeedback>;
		}

		return (
		<div>
			<Input {...opts} {...inputProps} value={this.props.value} />
			{feedback}
		</div>
		);
}
}