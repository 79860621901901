import React from 'react';
import Select from 'react-select';
import AsyncSelect  from 'react-select/lib/Async';
import { FormFeedback } from 'reactstrap';

class ValidatableDropdown extends React.Component {
	render() {
		const inputProps = Object.assign({}, this.props);
		delete inputProps.error;
		delete inputProps.async;

		if (this.props.error !== undefined) {
			inputProps['className'] = (inputProps['className'] !== undefined ? inputProps['className'] : '') + ' danger';
		}

		let feedback;

		if (this.props.error !== undefined) {
			feedback = <FormFeedback className="text-danger">{this.props.error}</FormFeedback>;
		}

		if(this.props.async){
			return <div><AsyncSelect isClearable escapeClearsValue {...inputProps} />
			{feedback}
			</div>
		}

		return <div>
			<Select {...inputProps} />
			{feedback}
		</div>
	}
}

export default ValidatableDropdown;