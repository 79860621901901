import React from 'react';
import { FormFeedback } from 'reactstrap';

export default class ValidatableRadio extends React.Component {
	render() {
		var opts = {};
		if (this.props.error !== undefined) {
			opts["state"] = "danger";
		}

		let feedback;

		if (this.props.error !== undefined) {
			feedback = <FormFeedback className="text-danger">{this.props.error}</FormFeedback>;
		}

		return (
		<div>
			{this.props.children}
			{feedback}
		</div>
		);
	}
}