import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { AccreditedCourse } from './components';
import InfiniteScroll from 'react-infinite-scroller';
import './styles.css';

const itemsPerPage = 20;

export default class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMoreItems: this.props.results.length > itemsPerPage,
            currentItem: itemsPerPage
        };
      }

      componentWillReceiveProps(newProps){
        if(this.props.isDirty !== newProps.isDirty){
            this.setState({ currentItem: itemsPerPage, hasMoreItems: newProps.results.length > itemsPerPage })
        }
      }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    getPaginatedResults(page) {
        var hasMore = this.state.currentItem < this.props.results.length;
        this.setState( { hasMoreItems: hasMore, currentItem : page * itemsPerPage });
    }

    generateList() {
        var items = this.props.results.map((result, i) => {
            if(i <= this.state.currentItem)
                return <AccreditedCourse key={i} className="accredited-course" course={result} />;
            return null;
        })

        return <InfiniteScroll className="ui grid results"
            pageStart={1}
            loadMore={this.getPaginatedResults.bind(this)}
            hasMore={this.state.hasMoreItems}>
            {items}
            <br/>
            {this.state.hasMoreItems ? null : <p>No more results</p>}
        </InfiniteScroll>
    }

    getNoResults = (dirty) => {
        if (dirty)
            return null;
        else
            return <em className="search-not-found">No courses have been found that match your criteria. Please refine your criteria and search again.</em>;
    }

    getResults = () => {
        return <Grid stackable>
            <Grid.Row>
                <em className="search-not-found">{`${this.props.results.length} course${this.props.results.length === 1 ? '' : 's'} found.`}</em>
            </Grid.Row>
            <Grid.Row className="results-head">
                <Grid.Column width={2} className="results-header">Award</Grid.Column>
                <Grid.Column width={4} className="results-header results-header-center">Intake</Grid.Column>
                <Grid.Column width={10} className="results-header results-header-center">Course</Grid.Column>
            </Grid.Row>
            {this.generateList()}
        </Grid>;
    }

    render() {
        if (this.props.results.length > 0 && !this.props.isDirty)
            return this.getResults();

        return this.getNoResults();
    }
}