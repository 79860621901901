import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';

class BcsRadio extends Component {
    getRow = () =>  <Grid.Row tabIndex="0" className={`bcs-radio${this.props.checked ? ' checked' : ''} ${this.props.className}`}>
        <Grid.Column width={10}>{this.props.display}</Grid.Column>
        <Grid.Column width={3}>{this.props.additionalDisplay}</Grid.Column>
        <Grid.Column width={3} className="radio-circle">{this.props.checked ? <i className="fa fa-check" aria-hidden="true"></i> : null }</Grid.Column>
    </Grid.Row>

    render() {
        return this.getRow()
    }
}

BcsRadio.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.any.isRequired,
    display: PropTypes.string.isRequired,

    className: PropTypes.string,
    additionalDisplay: PropTypes.string
}

BcsRadio.defaultProps = {
    className: ''
}

export default BcsRadio;